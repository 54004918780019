<template>
    <div>MSC 2</div>
</template>

<script>
export default {
    name: "CruiseTab",
};
</script>

<style lang="sass" scoped></style>
